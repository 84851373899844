import React from 'react';
import { Section, Image, Heading } from 'components';
import './styles.scss';

const HeadingImage = props => {
  const { data, context } = props;
  const { category } = context;
  const { document } = category;
  const { data: docData } = document;
  const { title: categoryTitle} = docData;
  const { primary } = data;
  const { title, image } = primary;
  const logo = context?.logo;

  return (
    <Section className="heading-image">
      {categoryTitle?.text && (
        <div className="heading-category">
          <h4>{categoryTitle.text}</h4>
        </div>
      )}
      <div className="heading-image-container">
        <div className="heading-image-background">
          <Image image={image} />
          <div className="heading-image-overlay" />
        </div>
        <div className="heading-image-logo">
          <div className="heading-image-logo-container">
            <Image image={logo} />
          </div>
        </div>
        <div className="heading-image-title">
          <Heading.Title title={title} />
        </div>
      </div>
    </Section>
  );
};

export default HeadingImage;
