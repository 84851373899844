import React from 'react';
import './styles.scss';

const HeadingTitle = props => {
  const { title } = props;
  return (
    <div className="heading">
      <h2 className="heading-title">{title?.text}</h2>
    </div>
  );
};

export default HeadingTitle;
