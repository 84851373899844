import React, { useState } from 'react';
import { linkResolver, classNames } from 'helpers';
import { Section, CardArticle, Wrapper } from 'components';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.scss';

const ListArticles = (props) => {
  const [selected, setSelected] = useState(null);

  const content = useStaticQuery(graphql`
    query ListArticles {
      allPrismicArticle(sort: { fields: data___date_published, order: DESC }) {
        nodes {
          id
          uid
          type
          data {
            date_published(formatString: "D MMM")
            title {
              text
            }
            description {
              html
            }
            category
            thumbnail {
              alt
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const itemsData = content?.allPrismicArticle?.nodes;
  const allOptions = itemsData?.map((item) => item?.data?.category);
  const options = Array.from(new Set(allOptions));
  const items = selected ? itemsData?.filter((item) => item?.data?.category === selected) : itemsData;

  options.sort();

  return (
    <Section className="list-articles" fluid background>
      <div className="list-articles-content">
        <Wrapper className="section-wrapper">
          <div className="list-filters">
            <div className={classNames('list-filter', { active: !selected })} onClick={() => setSelected(null)}>
              All
            </div>
            {options.map((option) => (
              <div
                key={option}
                className={classNames('list-filter', { active: selected === option })}
                role="button"
                onClick={() => setSelected(option)}
              >
                {option}
              </div>
            ))}
          </div>
          <div className="list-items">
            {items.map((item, index) => {
              const { uid, type, data: dataItem } = item;
              const {
                title: titleItem,
                category,
                description,
                thumbnail,
                video,
                logo,
                date_published: date,
              } = dataItem;
              const url = linkResolver(uid, type);
              return (
                <CardArticle
                  key={uid}
                  url={url}
                  title={titleItem}
                  description={description}
                  logo={logo}
                  thumbnail={thumbnail}
                  video={video}
                  category={category}
                  index={index}
                  date={date}
                />
              );
            })}
          </div>
        </Wrapper>
      </div>
    </Section>
  );
};

export default ListArticles;
