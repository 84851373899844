import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Footer, Header, SEO } from 'components';
import 'typeface-roboto';
import 'typeface-barlow';
import '../../sass/global/styles.scss';
import './styles.scss';

const Layout = props => {
  const content = useStaticQuery(graphql`
    query Footer {
      prismicSettings {
        data {
          email
          address {
            html
          }
          privacy {
            uid
            url
            type
          }
          terms {
            uid
            url
            type
          }
          socials {
            network
            url {
              url
            }
          }
          top_navigation {
            ... on PrismicSettingsTopNavigationMenu {
              id
              items {
                submenu_label {
                  text
                }
                submenu_link {
                  url
                  uid
                }
              }
              primary {
                menu_link {
                  url
                  uid
                  type
                }
                menu_label {
                  text
                }
              }
            }
          }
          bottom_navigation {
            ... on PrismicSettingsBottomNavigationMenu {
              id
              items {
                submenu_label {
                  text
                }
                submenu_link {
                  url
                  uid
                  type
                  document {
                    ... on PrismicProject {
                      id
                      data {
                        category {
                          uid
                          type
                        }
                      }
                    }
                    ... on PrismicService {
                      id
                      data {
                        category {
                          uid
                          type
                        }
                      }
                    }
                  }
                }
              }
              primary {
                label {
                  text
                }
              }
            }
          }
        }
      }
    }
  `);

  const { children, seo, floating } = props;
  const data = content?.prismicSettings?.data;
  const {
    top_navigation: topNavigation,
    bottom_navigation: bottomNavigation,
    socials,
    email,
    address,
    privacy,
    terms,
  } = data || {};

  return (
    <>
      <Header floating={floating} topNavigation={topNavigation} />
      <main>
        {seo && <SEO {...seo} />}
        {children}
      </main>
      <Footer
        bottomNavigation={bottomNavigation}
        socials={socials}
        email={email}
        address={address}
        privacy={privacy}
        terms={terms}
      />
    </>
  );
};

export default Layout;
