import React from 'react';
import { Section, Heading, VideoPlayer, Social, Form as FormComponent } from 'components';
import { useStaticQuery, graphql } from 'gatsby';

import './styles.scss';

const fields = {
  firstname: { required: true, value: null },
  surname: { required: true, value: null },
  email: { required: true, value: null },
  message: { required: true, value: null },
};

const Form = props => {
  const content = useStaticQuery(graphql`
    query FornContact {
      prismicSettings {
        data {
          email
          address {
            html
          }
          socials {
            network
            url {
              url
            }
          }
        }
      }
    }
  `);

  const { data } = props;
  const { primary } = data;
  const { title, description1: description, form_title: formTitle, form_button: formButton, video } = primary;
  const { email, address, socials } = content?.prismicSettings?.data || {};

  return (
    <Section className="section-form">
      <div className="section-form-container">
        <div className="form-info">
          <Heading.Title title={title} />
          <VideoPlayer className="form-info-video" src={video?.url} />
          <div className="form-info-description" dangerouslySetInnerHTML={{ __html: description?.html }} />
          <div className="form-info-address" dangerouslySetInnerHTML={{ __html: address?.html }} />
          <a href={`mailto:${email}`}>{email}</a>
          <div className="form-info-socials">
            <h3>Follow us</h3>
            <Social socials={socials} variation="contact" />
          </div>
        </div>
        <div className="form-fields">
          <Heading.Title title={formTitle} />
          <FormComponent name="contact" fields={fields} button={formButton}>
            <FormComponent.TextField name="firstname" placeholder="First name" />
            <FormComponent.TextField name="surname" placeholder="Surname" />
            <FormComponent.TextField name="email" placeholder="Email" />
            <FormComponent.TextArea name="message" placeholder="Message" />
          </FormComponent>
        </div>
      </div>
    </Section>
  );
};

export default Form;
