import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'helpers';
import IconsSVG from 'images/icons';
import { IconLibrary } from './IconLibrary/IconLibrary';
// import { IconLoader } from './IconLoader/IconLoader';
import './styles.scss';

// https://dmfrancisco.github.io/react-icons/

const variations = {
  primary: 'Midnight',
  secondary: 'Orange',
  quaternary: 'Yellow',
  quinary: 'Sky',
  midgrey: 'mid-grey',
  darkgrey: 'dark-grey',
  white: 'white',
  whiteblue: 'white-blue',
};

const Icon = (props) => {
  const { title, className, size, variation } = props;
  console.log('TITLE', title);
  const classes = classNames('icon', className, size);

  //-------------------
  // IconsSVG
  const iconSVG = IconsSVG[`${title}_${variations[variation]}`];
  if (iconSVG?.default) return <img className={classes} alt={title} src={iconSVG.default} />;

  //-------------------
  // IconsLibary
  if (!IconLibrary[title] || !iconSVG) {
    console.log(`@Elements/Icon - ${title} could not be found.`);
    return null;
  }

  const { path, width = 30, height = 30 } = IconLibrary[title];

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`}>
      <title>{title}</title>
      <path d={path} />
    </svg>
  );
};

Icon.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
};

Icon.defaultProps = {
  variation: 'secondary',
  className: null,
  size: 'sm',
};

// Icon.Loader = IconLoader;

export default Icon;
